/**
 * Grid -- generic grid
 */

@import "variables";

// Implementation

@import "row";
@import "column";

.grid {
  padding-top: 1.5em;
  padding-bottom: 1.5em;

  overflow: hidden;

  &.grid--no-top-padding {
    padding-top: 0;
  }
  &.grid--no-bottom-padding {
    padding-bottom: 0;
  }

  @include media-breakpoint-up(sm) {
    padding-top: 3em;
    padding-bottom: 3em;

    &.grid--no-top-padding {
      padding-top: 0;
    }
    &.grid--no-bottom-padding {
      padding-bottom: 0;
    }
  }

  .container--full-width {
    .row {
      .glide__arrow {
        &.glide__arrow--right {
          right: 20px;
        }
        &.glide__arrow--left {
          left: 20px;
        }
      }
    }
  }

  &.grid--video {
    position: relative;
  }
}

.grid__inner {
  position: relative;
}

.grid--edit {
  .row {
    position: relative;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;
      width: 100%;
      height: 100%;
      border: 1px dashed $gray-400;
      content: " ";
      pointer-events: none;
    }

    > .column,
    > [class*="column--"] {
      position: relative;

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100%;
        height: 100%;
        border: 1px dashed $gray-400;
        content: " ";
        pointer-events: none;
      }
    }
  }
}

.row__classes {
  position: absolute;
  bottom: 1px;
  left: 1px;
  background: $gray-200;
  color: $gray-600;
  font-size: 14px;
  padding: 2px 5px;
  border: 1px dashed gray-300;
  border-left: 0;
  border-top: 0;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.75);
  z-index: 999;
}

.column__classes {
  position: absolute;
  top: 1px;
  left: 1px;
  background: $gray-200;
  color: $gray-600;
  font-size: 14px;
  padding: 2px 5px;
  border: 1px dashed gray-300;
  border-left: 0;
  border-top: 0;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.75);
  z-index: 999;
}

// Background image
.grid__background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;

  &[data-align^="top-"] {
    background-position-y: top;
  }
  &[data-align^="middle-"] {
    background-position-y: center;
  }
  &[data-align^="bottom-"] {
    background-position-y: bottom;
  }

  &[data-align$="-left"] {
    background-position-x: left;
  }
  &[data-align$="-center"] {
    background-position-x: center;
  }
  &[data-align$="-right"] {
    background-position-x: right;
  }
}

// Background video styles
.grid__video {
  // display: block;
  // position: absolute;
  // top: 0;
  // left: 0;
  // bottom: 0;
  // right: 0;
  // width: 100% !important;
  // height: auto !important;

  /** Simulationg background-size: cover */
  object-fit: cover;
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;
}

.grid__video-wrapper {
  width: 100%;
  height: 100%;

  /* Will not allow the video to overflow the
  container */
  overflow: hidden;

  /* Centering the container's content vertically
  and horizontally */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

//play pause button
.grid__video-control-overlay {
  position: absolute;
  background-color: transparent;
  color: white;
  z-index: 9;
  cursor: pointer;
  padding: 1em;

  svg {
    width: 2em;
    height: 2em;
  }

  .icon--style--white {
    color: white;
  }
  .icon--style--dark {
    color: black;
  }

  .grid__video-control-overlay__pause {
    display: none;
  }

  .grid__video-control-overlay__play {
    display: inline-flex;
  }
}

.grid__video-control-overlay__position-sm {
  bottom: 0px;
  right: 0px;
}

.grid__video-control-overlay__position-lg {
  bottom: 0px;
  right: 0px;
}
