@import "mixins/breakpoints";

$row-height-extra-small--mobile: 200px;
$row-height-small--mobile: 250px;
$row-height-medium--mobile: 450px;
$row-height-large--mobile: 500px;
$row-height-extra-large--mobile: 650px;

$row-height-extra-small: 300px;
$row-height-small: 450px;
$row-height-medium: 600px;
$row-height-large: 700px;
$row-height-extra-large: 900px;

// base
.row {
  display: flex;
  flex-wrap: wrap;

  .glide__track {
    position: relative;
  }

  .row-glide__theme-light {
    .glide__arrow {
      background-color: $gray-900;
      color: $white;

      &:hover {
        background-color: $gray-800;
      }
    }
  }
  .row-glide__theme-dark {
    .glide__arrow {
      background-color: $gray-100;
      color: $gray-900;

      &:hover {
        background-color: $gray-400;
      }
    }
  }

  .glide__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50px;
    border: none;

    &:focus {
      outline: 3px solid $purple-500;
      outline-offset: 2px;
    }

    &.glide__arrow--right {
      right: -14px;
    }
    &.glide__arrow--left {
      left: -14px;
    }

    &.glide__arrow--disabled {
      cursor: default;
      background-color: $gray-200;
      color: $gray-600;
      opacity: 0.8;
    }

    svg {
      width: auto;
    }
  }
  // TODO: add gradients maybe?
  // .peek-before__gradient,
  // .peek-after__gradient {
  //   width: 50px;
  //   height: 100%;
  //   top: 0;
  //   position: absolute;
  //   &:before {
  //     content: "";
  //     position: absolute;
  //     height: 100%;
  //     width: 100%;
  //     bottom: 0;
  //     opacity: 1;
  //     z-index: 1;
  //   }
  //   @include media-breakpoint-up(sm) {
  //     width: 70px;
  //   }
  //   @include media-breakpoint-up(md) {
  //     width: 90px;
  //   }
  //   @include media-breakpoint-up(lg) {
  //     width: 150px;
  //   }
  //   @include media-breakpoint-up(xl) {
  //     width: 200px;
  //   }
  // }
  // .peek-before__gradient {
  //   left: 0;
  //   &::before {
  //     background: linear-gradient(
  //       to right,
  //       rgb(167, 167, 167),
  //       rgba(0, 0, 0, 0)
  //     );
  //   }
  // }
  // .peek-after__gradient {
  //   right: 0;
  //   &::before {
  //     background: linear-gradient(to left, rgb(60, 60, 60), rgba(0, 0, 0, 0));
  //   }
  // }
}

// remove min height on nested rows content groups
.row--full-height,
.row--extra-small,
.row--small,
.row--medium,
.row--large,
.row--extra-large {
  .row {
    .content-group {
      min-height: 0;
    }
  }
}

// Height
.row--auto {
  min-height: 0;
}
.row--full-height {
  min-height: calc(100vh - 46.875px);
  .glide__slides,
  .content-group {
    min-height: calc(100vh - 46.875px);
  }
}
.row--extra-small {
  min-height: $row-height-extra-small--mobile;
  .glide__slides,
  .content-group {
    min-height: $row-height-extra-small--mobile;
  }
}
.row--small {
  min-height: $row-height-small--mobile;
  .glide__slides,
  .content-group {
    min-height: $row-height-small--mobile;
  }
}
.row--medium {
  min-height: $row-height-medium--mobile;
  .glide__slides,
  .content-group {
    min-height: $row-height-medium--mobile;
  }
}
.row--large {
  min-height: $row-height-large--mobile;
  .glide__slides,
  .content-group {
    min-height: $row-height-large--mobile;
  }
}
.row--extra-large {
  min-height: $row-height-extra-large--mobile;
  .glide__slides,
  .content-group {
    min-height: $row-height-extra-large--mobile;
  }
}

@include media-breakpoint-up(sm) {
  .row--full-height {
    min-height: calc(100vh - 63.813px);
    .glide__slides,
    .content-group {
      min-height: calc(100vh - 63.813px);
    }
  }
  .row--extra-small {
    min-height: $row-height-extra-small;
    .glide__slides,
    .content-group {
      min-height: $row-height-extra-small;
    }
  }
  .row--small {
    min-height: $row-height-small;
    .glide__slides,
    .content-group {
      min-height: $row-height-small;
    }
  }
  .row--medium {
    min-height: $row-height-medium;
    .glide__slides,
    .content-group {
      min-height: $row-height-medium;
    }
  }
  .row--large {
    min-height: $row-height-large;
    .glide__slides,
    .content-group {
      min-height: $row-height-large;
    }
  }
  .row--extra-large {
    min-height: $row-height-extra-large;
    .glide__slides,
    .content-group {
      min-height: $row-height-extra-large;
    }
  }
}
